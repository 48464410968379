import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// const endpoint = "http://localhost:8000/api";
const endpoint = "https://laravel-back.marclopez.xyz/public/api";

const ProductDetail = () => {
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getProduct();
    getCategory();
  }, []);

  const getProduct = async () => {
    const response = await axios.get(`${endpoint}/products/${id}`);
    setProduct(response.data.data);
  };

  const getCategory = async () => {
    try {
      const response = await axios.get(`${endpoint}/products/category/${id}`);
      setCategory(response.data.data);
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  return (
    <div>
      <a>{product.title}</a>
      <br></br>
      <a>{product.body}</a>
      <br></br>
      <a>{product.price}</a>
      <br></br>
      {category && <p>Category: {category.category}</p>}
      <Link to="/">BACK</Link>
    </div>
  );
};

export default ProductDetail;
