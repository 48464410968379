import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

// const endpoint = "http://localhost:8000/api";
const endpoint = "https://laravel-back.marclopez.xyz/public/api";

const ShowCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    const response = await axios.get(`${endpoint}/categories`);
    setCategories(response.data.data);
  };

  return (
    <div className="page">
      {categories.map((category) => (
        <Link
          to={`/categories/${category.id}`}
          key={category.id}
          className="product_card"
        >
          <a>{category.category}</a>
        </Link>
      ))}
      <Link to="/">PRODUCTS</Link>
    </div>
  )
}

export default ShowCategories