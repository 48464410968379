import axios from "axios";
import React, { useEffect, useState } from "react";
import "./ShowProducts.css";
import { Link } from "react-router-dom";

// const endpoint = "http://localhost:8000/api";
const endpoint = "https://laravel-back.marclopez.xyz/public/api";

const ShowProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getAllProducts();

    const intervalId = setInterval(() => {
      getAllProducts();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const getAllProducts = async () => {
    const response = await axios.get(`${endpoint}/products`);
    setProducts(response.data.data);
  };

  return (
    <div className="page">
      {products.map((product) => (
        <Link
          to={`/products/${product.id}`}
          key={product.id}
          className="product_card"
        >
          <a>{product.title}</a>
          <span>{product.body}</span>
          <span>{product.price}</span>
          <span>{product.categories[0].category}</span>
        </Link>
      ))}
      <Link to="/categories">CATEGORIES</Link>
    </div>
  );
};

export default ShowProducts;
