import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ShowProducts from "./components/ShowProducts/ShowProducts";
import ProductDetail from "./components/ProductDetail/ProductDetail";
import ShowCategories from "./components/ShowCategories/ShowCategories";
import CategoryDetail from "./components/CategoryDetail/CategoryDetail";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ShowProducts />} />
          <Route path="/products/:id" element={<ProductDetail />}/>
          <Route path="/categories" element={<ShowCategories />}/>
          <Route path="/categories/:categoryId" element={<CategoryDetail />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
