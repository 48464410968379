import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// const endpoint = "http://localhost:8000/api";
const endpoint = "https://laravel-back.marclopez.xyz/public/api";

const CategoryDetail = () => {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const { categoryId } = useParams();

  useEffect(() => {
    getCategory();
    getProduct();
  }, [categoryId]);

  const getCategory = async () => {
    try {
      const response = await axios.get(
        `${endpoint}/categories/${categoryId}`
      );
      setCategory(response.data.data);
    } catch (error) {
      console.error("Error fetching products by category:", error);
    }
  };

  const getProduct = async () => {
    try {
      const response = await axios.get(
        `${endpoint}/products/categories/${categoryId}`
      );
      setProducts(response.data.data);
    } catch (error) {
      console.error("Error fetching products by category:", error);
    }
  };

  useEffect(() =>{
    console.log(products);
  }, [products]);

  return (
    <div>
      <h2>Products in {category.category}</h2>
      {products.length > 0 ? (
        <div>
          {products.map((product) => (
            <div key={product.id}>
              <Link to={`/products/${product.id}`} className="product_card">
                {product.title}
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p>No products found in this category.</p>
      )}
      <Link to="/categories">BACK</Link>
    </div>
  );
};

export default CategoryDetail;
